import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Telemetry, Config } from './interfaces';

@Injectable({
    providedIn: 'root',
})
export class NodeService {
    constructor(private apollo: Apollo) {}

    async getTelemetry(): Promise<Telemetry> {
        const query = gql`
            {
                telemetry {
                    queuedToSend
                    peers {
                        at
                        id {
                            network
                            index
                        }
                        lastSeen
                    }
                    globalPeerLists {
                        from {
                            network
                            index
                        }
                        peers {
                            at
                            id {
                                network
                                index
                            }
                            lastSeen
                        }
                        timestamp
                    }
                }
            }
        `;

        interface ReturnValue {
            telemetry: Telemetry;
        }

        const resp = await this.apollo.watchQuery({ query }).result();

        console.log(resp);

        return (resp.data as ReturnValue).telemetry;
    }

    async getConfig(): Promise<Config> {
        const query = gql`
            {
                config {
                    network
                    index
                    validator
                    postman
                    local
                    listenerIp
                    listenerPort
                    knownNodes
                    storeTransactionsFrom
                }
            }
        `;

        interface ReturnValue {
            config: Config;
        }

        const resp = await this.apollo.watchQuery({ query }).result();
        return (resp.data as ReturnValue).config;
    }

    async requestTransactionById(id: string): Promise<boolean> {
        const query = gql`
            {
                requestTransactionById(id: "${id}")
            }
        `;

        interface ReturnValue {
            requestTransactionById: boolean;
        }

        const resp = await this.apollo.watchQuery({ query }).result();
        return (resp.data as ReturnValue).requestTransactionById;
    }
}
