import { Component, OnInit } from '@angular/core';
import { NodeService } from '../node.service';

@Component({
    selector: 'app-network-graph',
    templateUrl: './network-graph.component.html',
    styleUrls: ['./network-graph.component.scss'],
})
export class NetworkGraphComponent implements OnInit {
    wait = (ms) => new Promise((r) => setTimeout(r, ms));

    show = true;

    options = {
        series: [
            {
                type: 'graph',
                // layout: 'force',
                layout: 'circular',
                // animation: false,
                symbolSize: 80,
                edgeSymbol: ['circle', 'arrow'],
                edgeSymbolSize: [4, 10],
                lineStyle: {
                    normal: {
                        opacity: 0.9,
                        width: 3,
                        // curveness: 0.1,
                    },
                },
                label: {
                    normal: {
                        show: true,
                        textStyle: {
                            fontSize: 12,
                        },
                    },
                },
                data: [],
                force: {
                    initLayout: 'circular',
                    // gravity: 0,
                    repulsion: 3500,
                    edgeLength: 400,
                    friction: 0.05,
                },
                edges: [],
            },
        ],
    };

    constructor(private nodeService: NodeService) {}

    async ngOnInit(): Promise<void> {
        this.update();
        // window.setInterval(() => {
        //     this.update();
        // }, 5000);
    }

    async update() {
        let telemetry = await this.nodeService.getTelemetry();
        let config = await this.nodeService.getConfig();
        console.log(config);

        let nodes = new Set();
        let connections = new Set();

        nodes.add(`${config.network}-${config.index}`);

        for (let peer of telemetry.peers) {
            console.log(peer);

            connections.add({
                source: `${config.network}-${config.index}`,
                target: `${peer.id.network}-${peer.id.index}`,
            });
        }

        for (const telemetryPeerList of telemetry.globalPeerLists) {
            const nodeName = `${telemetryPeerList.from.network}-${telemetryPeerList.from.index}`;
            nodes.add(nodeName);

            for (const peer of telemetryPeerList.peers) {
                connections.add({
                    source: nodeName,
                    target: `${peer.id.network}-${peer.id.index}`,
                });

                nodes.add(nodeName);
                nodes.add(`${peer.id.network}-${peer.id.index}`);
            }
        }

        const data = [];
        const nameMapping = {
            'flex_chain-0': 'AWSI',
            'flex_chain-1': 'OLI',
            'flex_chain-3': 'SWSL',
        };
        // the sort makes it more understandable (the circle will have the nodes
        // sorted around it)

        Array.from(nodes)
            .sort()
            .map((nodeName) => {
                const mappedName = nameMapping[nodeName as string];

                data.push({
                    id: mappedName,
                    name: mappedName,
                });
                connections.add({
                    source: mappedName,
                    target: mappedName,
                });
            });

        const edges = Array.from(connections).map((connection) => {
            const source = nameMapping[connection['source']];
            const target = nameMapping[connection['target']];

            return {
                source: source,
                target: target,
            };
        });

        this.options.series[0].data = data;
        this.options.series[0].edges = edges;

        console.log(telemetry.globalPeerLists.length);
        console.log(this.options.series[0].edges);
        console.log(this.options.series[0].data);

        this.show = false;
        await this.wait(100);
        this.show = true;
    }
}
