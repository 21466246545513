import { Component, OnInit } from '@angular/core';
import {
    register,
    RegisterRequest,
    SignRequest,
    SignResponse,
    sign,
} from 'u2f-api';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
    constructor() {}

    async ngOnInit(): Promise<void> {
        // let registerRequest: RegisterRequest = {
        //     version: '5',
        //     appId: 'safe-ur-chain',
        //     challenge: 'asdf',
        // };
        // register(registerRequest);
    }
}
