import { Component, EventEmitter } from '@angular/core';
import {
    UploadOutput,
    UploadInput,
    UploadFile,
    humanizeBytes,
    UploaderOptions,
} from 'ngx-uploader';
import { BlockchainService } from '../blockchain.service';

@Component({
    selector: 'app-file-hash',
    templateUrl: './file-hash.component.html',
    styleUrls: ['./file-hash.component.scss'],
})
export class FileHashComponent {
    options: UploaderOptions;
    formData: FormData;
    files: UploadFile[];
    uploadInput: EventEmitter<UploadInput>;
    humanizeBytes: any;
    dragOver: boolean;

    lastState: string = null;

    uploadReason = 'broadcast';

    transactionHashes: string[];

    constructor(private blockchainService: BlockchainService) {
        this.options = {
            concurrency: 1,
        };
        this.files = []; // local uploading files array
        this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
        this.humanizeBytes = humanizeBytes;
    }

    wait = (ms) => new Promise((r) => setTimeout(r, ms));

    async onUploadOutput(output: UploadOutput) {
        console.log(output.type);
        switch (output.type) {
            case 'allAddedToQueue':
                this.startUpload();
                break;
            case 'addedToQueue':
                if (typeof output.file !== 'undefined') {
                    this.files.push(output.file);
                }
                break;
            case 'uploading':
                if (typeof output.file !== 'undefined') {
                    // update current data in files array for uploading file
                    const index = this.files.findIndex(
                        (file) =>
                            typeof output.file !== 'undefined' &&
                            file.id === output.file.id
                    );
                    this.files[index] = output.file;
                }
                break;
            case 'removed':
                // remove file from array when removed
                this.files = this.files.filter(
                    (file: UploadFile) => file !== output.file
                );
                break;
            case 'dragOver':
                this.dragOver = true;
                break;
            case 'dragOut':
            case 'drop':
                this.dragOver = false;
                break;
            case 'done':
                console.log(this.files);

                break;
        }
    }

    async startUpload() {
        let url = 'http://localhost:7878/broadcast/';
        if (this.uploadReason === 'retrieve') {
            url = 'http://localhost:7878/retrieve/';
        }

        const event: UploadInput = {
            type: 'uploadAll',
            url,
            method: 'POST',
        };

        this.uploadInput.emit(event);
        this.waitForServerSideCheck();
    }

    cancelUpload(id: string): void {
        this.uploadInput.emit({ type: 'cancel', id });
    }

    removeFile(id: string): void {
        this.uploadInput.emit({ type: 'remove', id });
    }

    removeAllFiles(): void {
        this.uploadInput.emit({ type: 'removeAll' });
        this.files = [];
    }

    async waitForServerSideCheck() {
        while (this.files[0].progress.status !== 2) {
            await this.wait(10);
        }

        this.lastState = this.files[0].response;
        this.removeAllFiles();
    }

    async searchTransactions() {
        this.transactionHashes = await this.blockchainService.transactionHashesForTag(
            this.lastState
        );
    }
}
