import { Component, OnInit } from '@angular/core';
import { BlockchainService } from '../blockchain.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
    transactionHashes: string[];
    searchTerm = '';

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private blockchainService: BlockchainService
    ) {}

    async ngOnInit(): Promise<void> {
        this.route.params.subscribe(async (params) => {
            const searchTerm = params['term'];
            this.searchTerm = searchTerm;

            if (searchTerm !== undefined) {
                this.transactionHashes =
                    await this.blockchainService.transactionHashesForTag(
                        searchTerm
                    );
            }
        });
    }
}
