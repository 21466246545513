import { Component, OnInit, Input } from '@angular/core';
import { Block } from '../interfaces';

@Component({
    selector: 'app-block-list',
    templateUrl: './block-list.component.html',
    styleUrls: ['./block-list.component.scss'],
})
export class BlockListComponent implements OnInit {
    @Input() blocks: Block[];

    constructor() {}

    ngOnInit(): void {}

    timeAsString(timestampWithMillis) {
        const options = {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        };

        let date = new Date(timestampWithMillis);

        return `${date.toLocaleDateString(
            'de-DE',
            options
        )} ${date.toLocaleTimeString('de-DE')}`;
    }
}
