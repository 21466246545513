import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-transaction-list',
    templateUrl: './transaction-list.component.html',
    styleUrls: ['./transaction-list.component.scss'],
})
export class TransactionListComponent implements OnInit {
    @Input() transactionHashes: string[];
    constructor() {}

    ngOnInit(): void {
        console.log(this.transactionHashes);
    }
}
