import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NodeService } from '../node.service';

@Component({
  selector: 'app-request-transaction',
  templateUrl: './request-transaction.component.html',
  styleUrls: ['./request-transaction.component.scss']
})
export class RequestTransactionComponent {
    txId: string;
    error: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private nodeService: NodeService
    ) { }


    async request() {
        if (await this.nodeService.requestTransactionById(this.txId)) {
            this.router.navigate(['transaction', this.txId]);
        } else {
            this.error = "Ungültige ID"
        }
    }
}
