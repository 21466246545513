<div id="search">
    <mat-form-field style="width: 480px;">
        <mat-label>Suchbegriff</mat-label>
        <input matInput [(ngModel)]="searchTerm">
    </mat-form-field>

    <button mat-button [routerLink]="['/search/', searchTerm]">Suchen</button>
</div>



<app-transaction-list [transactionHashes]="transactionHashes"></app-transaction-list>
