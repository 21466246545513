<mat-toolbar class="mat-elevation-z4" *ngIf="this.config">
    <span class="material-icons">
        developer_board
    </span>

    &nbsp;{{config.network}} : {{config.index}}
    <button role="link" mat-button routerLink="blocks">Blöcke</button>
    <button role="link" mat-button routerLink="new_tx">Transaktion erstellen</button>
    <button role="link" mat-button routerLink="request_transaction">Daten anfordern</button>
    <button role="link" mat-button routerLink="network_graph">Netzwerk Graph</button>
    <button role="link" mat-button routerLink="search">Suche</button>
    <!-- <button role="link" mat-button routerLink="auth">Auth</button> -->
    <span style="margin-left: auto;" *ngIf="telemetry">
        <span *ngIf="telemetry.peers.length ==1">1 peer</span>
        <span *ngIf="telemetry.peers.length > 1">{{telemetry.peers.length}} peers</span>
    </span>
</mat-toolbar>
