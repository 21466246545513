<div *ngIf="storedTransaction">

    <h1>Transaktion {{storedTransaction.transaction.id}}</h1>
    <div *ngIf="storedTransaction">
        &nbsp;Signiert von:
        {{storedTransaction.transaction.signedHash.networkId}}-{{storedTransaction.transaction.signedHash.nodeIndex}}<br>
        &nbsp;Enthalten in <a [routerLink]="['/block/', storedTransaction.blockHeight]">Block
            #{{storedTransaction.blockHeight}}</a>
    </div>

    <br>
    <br>




    <h2>Inhalt</h2>
    <h3>Datentyp: {{storedTransaction.transaction.contents}}<button *ngIf="!showRaw"
            (click)="showRaw = !showRaw">Rohdaten anzeigen</button>
        <button *ngIf="showRaw" (click)="showRaw = !showRaw">Formatiert anzeigen</button>
    </h3>


    <pre *ngIf="showRaw">{{storedTransaction.transaction.rawContents}}</pre>


    <div *ngIf="storedTransaction.transaction.contents !== 'JSON'">
        <pre *ngIf="!showRaw" style="white-space: pre-line;">{{storedTransaction.transaction.stringContents}}</pre>
    </div>

    <div *ngIf="storedTransaction.transaction.contents === 'JSON'">
        <pre *ngIf="!showRaw" style="height: 800px; overflow: scroll;">{{jsonAsObj | json}}</pre>
    </div>

    <br>
    <br>

    <h2>Tags</h2>
    <mat-chip-list>
        <div *ngFor="let tag of tags">
            <a [routerLink]="['/search/', tag]">
                <mat-chip color="primary" selected>{{tag}}</mat-chip>
            </a>
        </div>
    </mat-chip-list>

    <br>
    <br>

    <h2>Vorgänger</h2>
    <div *ngFor="let txId of previousTransactions">
        <a [routerLink]="['/transaction/', txId]" (click)="id = txId; update();">
            {{txId}}
        </a>
        <br>
    </div>
</div>



<div *ngIf="storedTransaction === null">
    <br>
    <br>
    <h1 style="width: 100vw; text-align: center;">Auf Transaktion warten.</h1>
    <br>
    <div style="width: 100vw; display: flex; justify-content: center;">
        <img src="assets/loader.svg" alt="">
    </div>
</div>
