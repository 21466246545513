import { Component, OnInit } from '@angular/core';
import { NodeService } from '../node.service';
import { Telemetry, Config, Block } from '../interfaces';

@Component({
    selector: 'app-menu-bar',
    templateUrl: './menu-bar.component.html',
    styleUrls: ['./menu-bar.component.scss'],
})
export class MenuBarComponent implements OnInit {
    telemetry: Telemetry;
    config: Config;
    topBlock: Block;

    constructor(private nodeService: NodeService) {}

    async ngOnInit(): Promise<void> {
        await this.periodicUpdate();
        this.config = await this.nodeService.getConfig();
    }

    async periodicUpdate(): Promise<void> {
        window.setInterval(async () => {
            this.telemetry = await this.nodeService.getTelemetry();
        }, 1000);
    }
}
