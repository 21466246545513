<div id="request">
    <mat-form-field style="width: 480px;">
        <mat-label>Transaktions-ID (hex, ohne 0x)</mat-label>
        <input matInput [(ngModel)]="txId">
    </mat-form-field>

    <button mat-button (click)="request()">Anfrage starten</button>

    <p id="error" *ngIf="error">{{error}}</p>
</div>
