import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Block, StoredTransaction, Transaction } from './interfaces';

@Injectable({
    providedIn: 'root',
})
export class BlockchainService {
    constructor(private apollo: Apollo) {}

    async getBlockHeight(): Promise<number> {
        interface ReturnValue {
            blockHeight: number;
        }
        const query = gql`
            {
                blockHeight
            }
        `;

        const resp = await this.apollo.watchQuery({ query }).result();
        const returnValue = resp.data as ReturnValue;
        return returnValue.blockHeight;
    }

    async getTopBlock(): Promise<Block> {
        const query = gql`
            {
                topBlock {
                    digest
                    header {
                        timestamp
                        previousDigest
                        difficulty
                        nonce
                        height
                    }
                    data {
                        root
                        leafs {
                            contents
                            transaction {
                                id
                                signedHash {
                                    networkId
                                    nodeIndex
                                    dataType
                                    data
                                    digest
                                    signature
                                }
                                contents
                                rawContents
                                stringContents
                            }
                        }
                    }
                }
            }
        `;

        interface ReturnValue {
            topBlock: Block;
        }

        const resp = await this.apollo.watchQuery({ query }).result();
        return (resp.data as ReturnValue).topBlock;
    }

    async blocks(from: number, to: number): Promise<Block[]> {
        const query = gql`
            {
                blocks(from: "${from}", to: "${to}") {
                    digest
                    header {
                        timestamp
                        previousDigest
                        difficulty
                        nonce
                        height
                    }
                }
            }
        `;

        interface ReturnValue {
            blocks: Block[];
        }

        const resp = await this.apollo.watchQuery({ query }).result();
        return (resp.data as ReturnValue).blocks;
    }

    async blockAtHeight(height: string): Promise<Block> {
        const query = gql`
            {
                blockAtHeight(height: "${height}") {
                    header {
                        timestamp
                        previousDigest
                        difficulty
                        nonce
                        height
                    }
                    digest
                }
            }
        `;

        interface ReturnValue {
            blockAtHeight: Block;
        }

        const resp = await this.apollo.watchQuery({ query }).result();
        return (resp.data as ReturnValue).blockAtHeight;
    }

    async transactionHashesInBlock(hash: string): Promise<string[]> {
        const query = gql`
            {
                transactionHashesInBlock(hash: "${hash}")
            }
        `;

        interface ReturnValue {
            transactionHashesInBlock: string[];
        }

        const resp = await this.apollo.watchQuery({ query }).result();
        return (resp.data as ReturnValue).transactionHashesInBlock;
    }

    async transactionHashesForTag(tag: string): Promise<string[]> {
        const query = gql`
            {
                transactionHashesForTag(tag: "${tag}")
            }
        `;

        interface ReturnValue {
            transactionHashesForTag: string[];
        }

        const resp = await this.apollo.watchQuery({ query }).result();
        return (resp.data as ReturnValue).transactionHashesForTag;
    }

    async transaction(id: string): Promise<StoredTransaction | null> {
        const query = gql`
            {
                storedTransaction(
                        id: "${id}"
                    ) {

                    transaction {
                        id
                        tags
                        signedHash {
                            networkId
                            nodeIndex
                            dataType
                            data
                            digest
                            signature
                        }
                        contents
                        stringContents
                        rawContents
                    }
                    blockHash
                    blockHeight
                }
            }
        `;

        interface ReturnValue {
            storedTransaction: StoredTransaction;
        }

        const resp = await this.apollo.watchQuery({ query }).result();
        if (resp.data === null) {
            return null;
        } else {
            return (resp.data as ReturnValue).storedTransaction;
        }
    }

    async broadcastTransaction(payload: string, tags: string): Promise<string> {
        const query = gql`
            {
                broadcastTransaction(payload: "${payload}", tags: "${tags}")
            }
        `;

        interface ReturnValue {
            broadcastTransaction: string;
        }

        const resp = await this.apollo.watchQuery({ query }).result();
        return (resp.data as ReturnValue).broadcastTransaction;
    }
}
