<h1 *ngIf="files[0] !== undefined && files[0].progress.data.percentage !== 100">Wird hohgeladen:
    {{files[0].progress.data.percentage}}%
</h1>
<h1 *ngIf="files[0] !== undefined && files[0].progress.data.percentage === 100 && files[0].response === undefined">
    Hash wird erzeugt...</h1>

<br>

<div id="drop-container" *ngIf="files.length === 0 && lastState === null" ngFileDrop [options]="options"
    (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" [ngClass]="{ 'is-drop-over': dragOver }">
    <p *ngIf="!dragOver">
        Datei ziehen &amp; ablegen oder <label for="file" id="browse"><b>Speicher durchsuchen</b></label>
    </p>
    <p *ngIf="dragOver">
        Zum Hochladen ablegen
    </p>
</div>

<input id="file" type="file" ngFileSelect [options]="options" (uploadOutput)="onUploadOutput($event)"
    [uploadInput]="uploadInput" style="display: none;">

<div *ngIf="!lastState && files[0] === undefined">
    Modus:
    <button *ngIf="uploadReason === 'broadcast'" mat-button (click)="uploadReason = 'retrieve'">Datei
        veröffentlichen</button>
    <button *ngIf="uploadReason === 'retrieve'" mat-button (click)="uploadReason = 'broadcast'">Datei
        überprüfen</button>
</div>

<div *ngIf="uploadReason === 'broadcast' && lastState !== null">
    <h3>Datei veröffentlicht!</h3>
    <a [routerLink]="['/transaction/', lastState]">Transaktion: {{lastState}}</a>
</div>
<div *ngIf="uploadReason === 'retrieve' && lastState !== null">
    <h2>Datei Hash: {{lastState}}</h2>
    <a [routerLink]=" ['/search/', lastState]">Zur Suche</a>
</div>

<app-transaction-list [transactionHashes]="transactionHashes"></app-transaction-list>
