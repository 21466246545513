<h1>Block #{{block.header.height}}</h1>

<p>{{block.digest}}</p>

<div *ngIf="transactions.length > 0">
    <h2>Transaktionen</h2>
    <app-transaction-list [transactionHashes]="transactions"></app-transaction-list>
</div>
<div *ngIf="transactions.length === 0">
    <h2>Keine Transaktionen</h2>
</div>
