<mat-form-field style="width: 100%;" appearance="fill">
    <mat-label>Payload</mat-label>
    <textarea matInput placeholder="" style="height: 150px;" [(ngModel)]="payload"></textarea>
</mat-form-field>

<mat-form-field style="width: 100%;" appearance="fill">
    <mat-label>Tags</mat-label>
    <textarea matInput placeholder="Mit `, ` (Komma + Leerzeichen) trennen" [(ngModel)]="tags"></textarea>
</mat-form-field>

<button mat-raised-button color="primary" (click)="broadcast()">Absenden</button>

<br><br>

<a *ngIf="id" [routerLink]="['/transaction/', id]">Transaktion: {{id}}</a>
