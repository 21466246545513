import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockchainService } from '../blockchain.service';
import { Block } from '../interfaces';

@Component({
    selector: 'app-block',
    templateUrl: './block.component.html',
    styleUrls: ['./block.component.scss'],
})
export class BlockComponent implements OnInit {
    block: Block;
    transactions: string[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private blockchainService: BlockchainService
    ) {}

    async ngOnInit(): Promise<void> {
        this.route.params.subscribe(async (params) => {
            const height = params['height'];

            if (height !== undefined) {
                this.block = await this.blockchainService.blockAtHeight(height);
                this.transactions = await this.blockchainService.transactionHashesInBlock(
                    this.block.digest
                );
            } else {
                this.router.navigateByUrl('/');
            }
        });
    }
}
