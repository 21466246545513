<table>
    <tr>
        <th style="width: 80px;">Höhe</th>
        <th style="width: 200px;">Validiert</th>
        <th>Hash</th>
    </tr>

    <tr *ngFor="let block of blocks">
        <td style="width: 80px;">
            #{{block.header.height}}
        </td>

        <td style="width: 200px;">
            {{timeAsString(+block.header.timestamp)}}
        </td>

        <td><a [routerLink]="['/block/', block.header.height]">{{block.digest}}</a></td>
    </tr>
</table>
