import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-new-tx',
    templateUrl: './new-tx.component.html',
    styleUrls: ['./new-tx.component.scss'],
})
export class NewTxComponent implements OnInit {
    action = 'text';

    constructor() {}

    ngOnInit(): void {}
}
