import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { BlockComponent } from './block/block.component';
import { BlocksComponent } from './blocks/blocks.component';
import { NetworkGraphComponent } from './network-graph/network-graph.component';
import { NewTxComponent } from './new-tx/new-tx.component';
import { RequestTransactionComponent } from './request-transaction/request-transaction.component';
import { SearchComponent } from './search/search.component';
import { TransactionComponent } from './transaction/transaction.component';

const routes: Routes = [
    { path: '', redirectTo: 'blocks', pathMatch: 'full' },
    { path: 'blocks', component: BlocksComponent },
    { path: 'new_tx', component: NewTxComponent },
    { path: 'network_graph', component: NetworkGraphComponent },
    { path: 'transaction/:id', component: TransactionComponent },
    { path: 'block/:height', component: BlockComponent },
    { path: 'search', component: SearchComponent },
    { path: 'search/:term', component: SearchComponent },
    { path: 'request_transaction', component: RequestTransactionComponent },
    { path: 'auth', component: AuthComponent },
];

@NgModule({
    imports: [CommonModule, RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
