import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxUploaderModule } from 'ngx-uploader';
import { NgxEchartsModule } from 'ngx-echarts';

import { MatPaginatorModule } from '@angular/material/paginator';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { MenuBarComponent } from './menu-bar/menu-bar.component';
import { FileHashComponent } from './file-hash/file-hash.component';
import { NetworkGraphComponent } from './network-graph/network-graph.component';
import { TransactionComponent } from './transaction/transaction.component';
import { BlocksComponent } from './blocks/blocks.component';
import { BlockComponent } from './block/block.component';
import { BlockListComponent } from './block-list/block-list.component';
import { SearchComponent } from './search/search.component';
import { FormsModule } from '@angular/forms';
import { TransactionListComponent } from './transaction-list/transaction-list.component';
import { RequestTransactionComponent } from './request-transaction/request-transaction.component';
import { BroadcastTxComponent } from './broadcast-tx/broadcast-tx.component';
import { NewTxComponent } from './new-tx/new-tx.component';
import { AuthComponent } from './auth/auth.component';

@NgModule({
    declarations: [
        AppComponent,
        MenuBarComponent,
        FileHashComponent,
        NetworkGraphComponent,
        TransactionComponent,
        BlocksComponent,
        BlockComponent,
        BlockListComponent,
        SearchComponent,
        TransactionListComponent,
        RequestTransactionComponent,
        BroadcastTxComponent,
        NewTxComponent,
        AuthComponent,
    ],
    imports: [
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'),
        }),
        GraphQLModule,
        NgxUploaderModule,
        FormsModule,

        MatPaginatorModule,
        MatToolbarModule,
        MatExpansionModule,
        MatButtonModule,
        MatChipsModule,
        MatInputModule,
        MatButtonToggleModule,

        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
    ],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
