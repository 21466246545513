import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { BlockchainService } from '../blockchain.service';
import { Block } from '../interfaces';

@Component({
    selector: 'app-blocks',
    templateUrl: './blocks.component.html',
    styleUrls: ['./blocks.component.scss'],
})
export class BlocksComponent implements OnInit {
    blockHeight = 0;
    pageEvent: PageEvent;
    blocks: Block[];

    pageIndex = 0;

    constructor(private blockchainService: BlockchainService) {}

    async ngOnInit(): Promise<void> {
        await this.updateBlockHeight();

        this.pageEvent = {
            pageSize: 25,
            length: this.blockHeight,
            pageIndex: Math.floor(this.blockHeight / 25),
        };

        this.pageIndex = Math.floor(this.blockHeight / 25);

        this.updateBlocks();
    }

    async updateBlockHeight(): Promise<void> {
        this.blockHeight = await this.blockchainService.getBlockHeight();
    }

    async updateBlocks(): Promise<void> {
        const from = this.pageEvent.pageSize * this.pageEvent.pageIndex;
        const to = (1 + this.pageEvent.pageIndex) * this.pageEvent.pageSize - 1;
        this.blocks = await this.blockchainService.blocks(from, to);
    }
}
