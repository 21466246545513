import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockchainService } from '../blockchain.service';
import { StoredTransaction } from '../interfaces';

@Component({
    selector: 'app-transaction',
    templateUrl: './transaction.component.html',
    styleUrls: ['./transaction.component.scss'],
})
export class TransactionComponent implements OnInit {
    id: string;
    storedTransaction: StoredTransaction;
    showRaw = false;

    tags: string[];
    previousTransactions: string[];

    jsonAsObj = {};

    wait = (ms) => new Promise((r) => setTimeout(r, ms));

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private blockchainService: BlockchainService
    ) {}

    async ngOnInit(): Promise<void> {
        this.route.params.subscribe(async (params) => {
            this.id = params['id'];
        });

        while (!this.storedTransaction) {
            await this.wait(100);
            this.update();
        }
    }

    async update() {
        if (this.id !== undefined) {
            this.storedTransaction = await this.blockchainService.transaction(
                this.id
            );

            if (this.storedTransaction.transaction.contents === 'JSON') {
                let jsonNoSingleQuotes =
                    this.storedTransaction.transaction.stringContents;
                jsonNoSingleQuotes = jsonNoSingleQuotes.replace(/'/g, '"');

                this.jsonAsObj = JSON.parse(jsonNoSingleQuotes);

                console.log(this.jsonAsObj);
            }

            this.tags = this.storedTransaction.transaction.tags.filter(
                (t) => t.indexOf('prev:') !== 0
            );

            this.previousTransactions = this.storedTransaction.transaction.tags
                .filter((t) => t.indexOf('prev:') === 0)
                .map((t) => t.substring(5));
        } else {
            this.router.navigateByUrl('/');
        }
    }
}
