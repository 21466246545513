import { Component, OnInit } from '@angular/core';
import { BlockchainService } from '../blockchain.service';

@Component({
    selector: 'app-broadcast-tx',
    templateUrl: './broadcast-tx.component.html',
    styleUrls: ['./broadcast-tx.component.scss'],
})
export class BroadcastTxComponent implements OnInit {
    payload = '';
    tags = '';
    id = '';

    constructor(private blockchainService: BlockchainService) {}

    ngOnInit(): void {}

    async broadcast() {
        let id = await this.blockchainService.broadcastTransaction(
            this.payload,
            this.tags
        );

        if (id == 'None') {
            alert('Es ist ein Fehler aufgetreten');
        } else {
            this.payload = '';
            this.tags = '';

            this.id = id;
        }
    }
}
